.group-list {
    .input-date {
        color: white;
        background-color: transparent;
    }

    .group-list-container {
        margin-top: 20px;
        padding-left: 15px;
        margin-bottom: 80px;
    }

    .group-list-create-section {
        margin-top: 20px;
        margin-bottom: 30px;
        border: #3b75b4 1px solid;
        padding: 20px 30px;
        border-radius: 6px;
    }

    .btn-section {
        height: 36px;
        padding: 5px 20px;
        border: #ffffff 1px solid;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 6px;
    }

    .create {
        background-color: #3b75b4;
        padding-left: 50px;
        padding-right: 50px;
    }

    .sort {
        padding: 0px;
        height: 100%;
        border-radius: 0px;
        border-width: 0px;
    }
}

.group-form-container {
    // position: relative;
    padding-top: 30px;
    padding-bottom: 60px;

    .group-form {
        width: 100%;
    }

    .input-row {
        margin-top: 15px;
    }

    .input-area {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .btn {
        min-width: 120px;
        margin-right: 5px;
        margin-left: 5px;
        border-radius: 8px;
    }
}

.template {
    border: #3b75b4 solid 1px;
    border-radius: 6px;
    height: 300px;
    background-color: white;
    cursor: pointer;
    overflow: auto;
    padding: 10px;
}

.email-template {
    all: initial;
    cursor: pointer;
}