.App {
    // background: radial-gradient(#1f1b2a, #131118);
    color: #ffffff;
    overflow-x: hidden;
    font-family: 'Sweet Sans Pro';
    font-weight: normal;
    font-style: normal;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    // background-color: #282c34;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn {
    min-width: 100px;
    font-size: 14px;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

.col {
    padding-left: 0px;
    padding-right: 0px;
}

.request-table {
    background: radial-gradient(#1f1b2a, #131118);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.notification-title {
    font-size: 12px;
    letter-spacing: 1px;
}

.notification-message {
    font-size: 10px;
    letter-spacing: 1px;
}
