.payment {
    height: 120vh;
    background: radial-gradient(#313543, #1f222c);

    .auth-logo {
        width: 212px;
        height: 67px;
    }

    @media only screen and (max-width: 600px) {
        .signup-form {
            // transform: translate(-50%, -50%);
            // width: 100%;
            max-width: 450px;
            width: 360px;
    
            .image-form-wrapper .input-group {
                width: auto;
            }
        }
    }

    @media only screen and (min-width: 600px) {
        .signup-form {
            position: fixed;
            display: block;
            top: calc(47vh);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            // max-width: 450px;
            // width: 300px;
    
            .image-form-wrapper .input-group {
                width: auto;
            }
        }
    }

    .register-btn {
        width: 160px;
        // height: 30px;
        border-radius: 4px !important;
        font-size: 12px;

        padding: 0.3rem;
        font-weight: bold;
        background-color: #1c89c9;
        border-color: #1c89c9;
    }

    .register-form-input {
        border: 1px solid white !important;
        border-radius: 6px !important;
        height: 32px;
        letter-spacing: 0.01em;

        font-size: 14px;
        color: #ffe;
        width: 250px;
    }

    .register-form-input.is-invalid {
        border: 1px solid #dc3545 !important;
    }

    .background-overlay {
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
        // background: linear-gradient(
        //     rgba(49, 53, 63, 0.58),
        //     rgba(49, 53, 67, 0.58)
        // );
        background: radial-gradient(#313543, #1f222c);
        z-index: -1;
    }

    .StripeElement {
        border: 1px solid white !important;
        border-radius: 6px !important;
        height: 32px;
        letter-spacing: 0.01em;

        font-size: 14px;
        color: #ffe;
        width: 250px;
        padding: 5px 15px 11px 0;
    }

    .join-now {

        font-size: 12px;
        color: #bbbdbf;
    }

    .join-now-link {
        color: #1c89c9;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b1b5ba;
        opacity: 1;
        /* Firefox */

        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 600;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b1b5ba;

        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 600;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b1b5ba;

        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 600;
    }

    .title {

        font-size: 22px;
        font-weight: bold;
        color: #fff;
    }

    .description {
        font-size: 12px;
        color: #929497;
        line-height: 13px;
        width: 250px;
        text-transform: capitalize;
    }

    .club-card {
        border: 1px solid rgba(34, 211, 311, 0.5) !important;
        border-radius: 6px;
        background-color: #313543;
        height: 150px;
        // min-width: 600px;

        .name {
            color: #929497;
            font-size: 12px;
        }

        .tier {
            color: #ffffff;
            font-size: 13px;
            letter-spacing: 0.1em;
            font-weight: bold;
        }

        .cancel {
            color: #929497;
            font-size: 12px;
        }

        .club-card-image {
            width: 600px;
        }

        @media (max-width:1540px) {
            .club-card-image {
                width: 400px;
            }
        }
    }
}