.right-bar {
    width: 300px;
    height: 100%;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 40px;
    overflow-y: auto;
    border-radius: 6px;

    .nav-wrapper {
        .nav {
            .nav-item {
                width: 100%;
                display: block;
                // background: #3a81bf;
                color: #ffffff;
                font-size: 16px;
                text-decoration: none;
                margin: 8px 0;
                padding: 5px 10px;
                border-radius: 6px;
                border: 1px solid #3a81bf;
            }
            .select-item {
                // background: white;
                // color: black;
                background: #3a81bf;
                color: #ffffff;
            }
        }
    }

    .right-bar-info {
        margin-top: 80px;
        h5 {
            font-weight: 400;
            margin: 20px 0 5px 0;
        }
        p {
            font-size: 12px;
            font-weight: lighter;
            margin: 0;
        }
        .circle-score-wrapper {
            position: relative;
            top: -20px;
            left: 10px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            padding-top: 15px;
            text-align: center;

            &::before {
                content: '';
                position: absolute;
                width: 30px;
                height: 60px;
                bottom: 0px;
                left: 0px;
                border: solid 3px #3a81bf;
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
                border-right: 0;
            }

            &::after {
                content: '';
                position: absolute;
                width: 60px;
                height: 30px;
                bottom: 0px;
                left: 0px;
                border: solid 3px #3a81bf;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                border-top: 0;
            }
        }
    }
}
