.header {
    padding-left: 300px;
    margin-top: 30px;
    width: 100%;
}

.header-sub {
    padding-left: 300px;
    padding-right: 100px;
    margin-top: 30px;
    width: 100%;
}

.nav-header {
    background: radial-gradient(#1f1b2a, #131118);
    height: 100px;
    padding-left: 0px;


    .logo {
        padding-left: 20px;
        width: 212px;
        height: 67px;
    }

    @media only screen and (max-width: 600px) {
        .logo {
            width: 128px;
        }
    }
}

.dashboard-header {
    background-color: #3B75B4;
    height: 300px;
    padding-top: 30px;
}

.dashboard-header-text {
    padding-left: '5vw';
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .dashboard-header {
        height: 600px;
    }

    .dashboard-header-text {
        height: 50%;
    }
}

.screenshot-container {
    position: relative;
    display: inline-block;
}

.screenshot-container:hover>.overlay {
    opacity: 1;
}

.screenshot {
    //   width: 300px;
    height: 270px;
    object-fit: cover;
    display: block;
}

.screenshot-overlay {
    background-color: #3B75B4;
    position: absolute;
    top: 66px;
    right: 18px;
    left: 18px;
    bottom: 146px;
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
}

#popover-contained {
    background: radial-gradient(#1f1b2a, #131118);
    width: 300px;

    .popover-body {
        .nav-item {
            color: #ffffff;
            border: 1px solid #3a81bf;
            padding: 5px 10px;
        }

        .select-item {
            background: #3a81bf;
            color: #ffffff;
            border: 1px solid #3a81bf;
            padding: 5px 10px;
        }
    }
}

.switch-club {
    .modal-content {
        background-color: #343a40;
    }

    .modal-header {
        align-items: center !important;
    }

    .modal-header .close {
        margin: -1rem -1rem -1rem 0 !important;
        color: #fff !important;
    }

    .modal-title {
        margin: 0 auto;
    }
}