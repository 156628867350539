.landing {
    height: 100vh;
    background: radial-gradient(#313543, #1f222c);

    @media only screen and (min-width: 600px) {
        .landing-logo {
            position: absolute;
            display: block;
            top: calc(45vh);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 450px;
            width: 300px;
        }

        .landing-form {
            position: absolute;
            display: block;
            top: calc(5vh);
            right: 32px;
            // transform: translate(-50%, -50%);
            // width: 100%;
            max-width: 450px;
            // width: 300px;
        }
    }

    .auth-logo {
        width: 212px;
        height: 67px;
        // transform: scale(0.8, 0.8);
    }

    .signup-btn {
        width: 160px;
        height: 30px;
        border-radius: 4px !important;
        font-size: 12px;

        padding: 0.3rem;
        font-weight: bold;
        background-color: #1c89c9;
        border-color: #1c89c9;
    }

    .login-btn {
        width: 160px;
        height: 30px;
        border-radius: 4px !important;
        font-size: 12px;

        padding: 0.3rem;
        font-weight: bold;
    }

    .background-overlay {
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
        // background: linear-gradient(
        //     rgba(49, 53, 63, 0.58),
        //     rgba(49, 53, 67, 0.58)
        // );
        background: radial-gradient(#313543, #1f222c);
        z-index: -1;
    }

    .description {

        font-size: 14px;
        font-weight: bold;
        color: #bbbdbf;
        margin-top: -6px;
        margin-bottom: 26px;
    }

    .footer {
        height: 100px;

        @media only screen and (max-width: 600px) {
            .footer-section {
                margin: auto;
            }
        }

        .items {
            margin: auto 50px;

            font-size: 10px;
            font-weight: bold;
            color: #bbbdbf;
        }
    }
}