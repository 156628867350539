.verify {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
    .verify-form {
        position: absolute;
        display: block;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 450px;
    }
    .verify-header {
        margin-top: 30px;
        width: 100%;
    }
}
