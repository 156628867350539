.onboarding {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
    .auth-logo {
        width: 212px;
        height: 67px;
    }

    .login-form {
        position: fixed;
        display: block;
        top: calc(47vh);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 450px;
        width: 300px;
    }
    .login-main-form {
        position: fixed;
        display: block;
        top: calc(40vh);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 450px;
        width: 243px;
    }
    .login-header {
        margin-top: 30px;
        width: 100%;
    }
    .login-menu-container {
        position: absolute;
        right: 20px;
        top: 20px;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }
    .login-menu {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
    }

    .login-btn {
        width: 80%;
        border-radius: 6px !important;
    }

    .login-form-input {
        border: 1px solid white !important;
        border-radius: 6px !important;
    }

    .login-memu-icon {
        font-size: 22px;
    }

    .login-items {
        background-color: white;
        color: black;
        font-size: 15px;
        padding: 4px 44px 4px 12px;
        margin-right: 8px;
        border-radius: 6px;
        border: 2px solid rgba(49, 53, 67, 0.88);
        cursor: pointer;
    }

    .background-overlay {
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
        background: linear-gradient(
            rgba(49, 53, 63, 0.58),
            rgba(49, 53, 67, 0.58)
        );
        z-index: -1;
    }

    .video {
        position: fixed;
        z-index: -2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.select-club {
    .modal-content {
        background-color: #343a40;
    }
}