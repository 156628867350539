.stepper {
    position: fixed;
    top: 32px;
    left: 0;

    .container {
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .title {
            font-size: 12px;
            font-weight: bold;
            color: #e4eef8;
            margin-bottom: 10px;
            text-align: center;
        }

        @media only screen and (max-width: 600px) {
            .content {
                display: flex;
            }
        }
    
        @media only screen and (min-width: 600px) {
            .content {
                margin-left: 14px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .step {
        position: relative;
        color: gray;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 600px) {
    .step {
        position: relative;
        color: gray;
        margin-right: 14px;
    }
}

.step.step1 {
    height: 6em;

    @media only screen and (max-width: 600px) {
        margin-right: 3.1em;
    }

    .circle {
        position: relative;
        width: 0.8em;
        height: 0.8em;
        line-height: 1.5em;
        border-radius: 100%;
    }

    @media only screen and (max-width: 600px) {
        .circle:after {
            content: ' ';
            position: absolute;
            display: block;
            top: 4px;
            right: 50%;
            bottom: 1px;
            left: 100%;
            height: 2px;
            width: 3.2em;
            // transform: scale(1, 2);
            transform-origin: 50% -90%;
            background: rgba(34, 211, 311, 0.25) // z-index: -1;
        }
    }

    @media only screen and (min-width: 600px) {
        .circle:after {
            content: ' ';
            position: absolute;
            display: block;
            top: 11px;
            right: 50%;
            bottom: 1px;
            left: 50%;
            height: 6.8em;
            width: 2px;
            // transform: scale(1, 2);
            transform-origin: 50% -90%;
            background: rgba(34, 211, 311, 0.25) // z-index: -1;
        }
    }
}

.step.step2 {
    height: 2em;

    @media only screen and (max-width: 600px) {
        margin-right: 1.5em;
    }

    .circle {
        position: relative;
        width: 0.8em;
        height: 0.8em;
        line-height: 1.5em;
        border-radius: 100%;
    }

    @media only screen and (max-width: 600px) {
        .circle:after {
            content: ' ';
            position: absolute;
            display: block;
            top: 4px;
            right: 50%;
            bottom: 1px;
            left: 100%;
            height: 2px;
            width: 1.6em;
            // transform: scale(1, 2);
            transform-origin: 50% -90%;
            background: rgba(34, 211, 311, 0.25) // z-index: -1;
        }
    }

    @media only screen and (min-width: 600px) {
        .circle:after {
            content: ' ';
            position: absolute;
            display: block;
            top: 11px;
            right: 50%;
            bottom: 1px;
            left: 50%;
            height: 2.8em;
            width: 2px;
            // transform: scale(1, 2);
            transform-origin: 50% -90%;
            background: rgba(34, 211, 311, 0.25) // z-index: -1;
        }
    }
}

@media only screen and (min-width: 600px) {
    .step+.step {
        margin-top: 1.5em
    }
}

.step>div:first-child {
    position: static;
    height: 0;
}

.step>div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}

.step:last-child .circle:after {
    display: none
}

.step .circle {
    border: 2px solid rgba(34, 211, 311, 0.25);
    background-color: transparent;
}

.step.step-active {
    color: #1c89c9;
}

.step.step-active .circle {
    background-color: #1c89c9;
}