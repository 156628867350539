body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(#1f1b2a, #131118);

  text-transform: uppercase !important;
}

button {
  text-transform: uppercase !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

@font-face {
  font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Regular.eot');
    src: url('./assets/fonts/SweetSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Regular.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Regular.ttf') format('truetype'),
        url('./assets/fonts/SweetSansPro-Regular.svg#SweetSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
